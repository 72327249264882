@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  /* width: 90%;
  margin: auto; */
}

/* @layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
} */